<!-- @format -->

<header class="snackbar-shadow bg-white">
	<div
		class="text-primary mx-auto flex w-full max-w-[1560px] items-center justify-between px-4 py-3 md:px-6"
	>
		<a routerLink="/">
			<img src="/assets/images/shared/logo.svg" alt="Литнет" />
		</a>
		<a *appAuthed="true" [routerLink]="['/profile']">
			<app-avatar *ngIf="user" [appUser]="user"></app-avatar>
		</a>
		<button
			class="btn btn-primary btn-transparent justify-end gap-1 !text-sm"
			type="button"
			*appAuthed="false"
			(click)="onLogin()"
		>
			<span class="hidden md:block">Войти</span>
			<i class="text-primary" appSvgIcon appIcon="login" appSquare="24"></i>
		</button>
	</div>
</header>
