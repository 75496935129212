/** @format */

import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ValidationErrors } from '@angular/forms';

const getErrorMap = (formControlName: string): any => {
  switch (formControlName) {
    case 'email':
      return {
        required: 'Заполните E-mail',
        email: 'Некорректный E-mail'
      };
    case 'password':
    case 'current_password':
    case 'password_confirmation':
    case 'new_password':
    case 'new_password_confirmation':
      return {
        isPassword:
          'Минимально — 6, максимально — 60 символов. Пароль должен содержать хотя бы одну цифру (от 0 до 9), либо букву верхнего регистра, либо специальный символ.',
        isSame: 'Пароли не совпадают'
      };
    case 'code':
      return {
        required: 'Заполните код',
        minlength: 'Некорректный код, минимально — 6 символов'
      };
    case 'id':
      return {
        required: 'Заполните ID пользователя',
        minlength: 'Некорректный ID'
      };
    case 'message':
      return {
        required: 'Заполните сообщение',
        minlength: 'Минимально — 6 символов'
      };
    case 'name':
      return {
        required: 'Заполните имя',
        maxlength: 'Максимум — 20 символов'
      };
    case 'last_name':
      return {
        required: 'Заполните фамилию',
        maxlength: 'Максимум — 20 символов'
      };
    default: {
      return null;
    }
  }
};

@Component({
  selector: 'app-error-tooltip',
  templateUrl: './error-tooltip.component.html'
})
export class ErrorTooltipComponent implements OnInit {
  @ViewChild('errorElement') errorElement!: ElementRef;

  @HostListener('document:click', ['$event'])
  onClick(event: any) {
    const elementParent: HTMLElement = this.errorElement?.nativeElement.parentElement.parentElement;
    const inParent: boolean = elementParent?.contains(event.target);

    this.closed.emit(inParent);
  }

  @Output() closed = new EventEmitter<boolean>();

  @Input()
  set appErrorsField(errorsField: ValidationErrors | null | undefined) {
    if (errorsField) {
      this.errorsControl = errorsField;
      this.onUpdateErrors();
    } else {
      this.errorsControl = null;
      this.onUpdateErrors();
    }
  }

  @Input()
  set appFormControlName(formControlName: string) {
    this.formControlName = formControlName;
    this.onUpdateErrors();
  }

  errorsControl?: ValidationErrors | null;
  formControlName: string = '';

  errors: string[] = [];

  constructor() {}

  ngOnInit(): void {}

  onUpdateErrors(): void {
    this.errors = [];

    if (this.errorsControl) {
      const keyValidationErrors: string[] = Object.keys(this.errorsControl);
      const errorMap = getErrorMap(this.formControlName);

      keyValidationErrors.forEach((keyError: string) => {
        if (errorMap && errorMap[keyError]) {
          this.errors.push(errorMap[keyError]);
        }
      });
    }
  }
}
