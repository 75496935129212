<!-- @format -->

<div class="relative overflow-hidden rounded-full" [ngClass]="appClassList">
	<i
		*ngIf="!appUser?.avatar"
		appSvgIcon
		appIcon="person"
		[appHeight]="appSquare"
		[appWidth]="appSquare"
		appViewBox="0 0 12 12"
	></i>
	<img
		#avatarEl
		class="absolute left-1/2 top-1/2 block -translate-x-1/2 -translate-y-1/2"
		*ngIf="!!appUser && !!appUser?.avatar"
		[src]="appUser.avatar"
		[alt]="appUser.name"
		[ngClass]="
			avatarEl.naturalWidth / avatarEl.naturalHeight <= 1
				? 'h-auto w-full'
				: 'h-full w-auto'
		"
	/>
</div>
