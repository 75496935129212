/** @format */

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { APP_CONFIG } from './app/core';

function bootstrap() {
  fetch('./assets/configurations/config.json')
    .then((response: any) => response.json())
    .then((response: any) => {
      const config: any = JSON.parse(atob(response.configuration));

      if (config.production) {
        enableProdMode();
      }

      const appConfigProvider: any = {
        provide: APP_CONFIG,
        useValue: config
      };

      platformBrowserDynamic([appConfigProvider])
        .bootstrapModule(AppModule)
        .catch(err => console.error(err));
    });
}

if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}
